@import "abstracts/_abstracts";
.ListManufacturersHp {
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid var(--colorOutlines);

    &-title {
        flex-shrink: 0;
        font-size: clamp(0.85rem, 1.5vw, 1rem);
        line-height: 1.5;
        margin: 0;
    }

    &-item {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
        overflow: hidden;
        display: flex;

        &:hover {
            img {
                filter: grayscale(0);
            }
        }

        img {
            transition: var(--animationBase);
            max-height: 3rem;
            filter: grayscale(1);
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: clamp(2rem, 5vw, 4rem);
    }

    &-swiper {
        position: relative;
        overflow: hidden;
        width: 100%;
        flex: 1;
    }

    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        transform: translate3d(0,0,0);
    }

    &-controls {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        flex-shrink: 0;
    }

    &-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 3rem;

        .swiper-pagination-current {
            font-weight: 700;
        }
    }

    &-button {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        color: var(--colorBrand);
        transition: var(--animationBase);

        @include on-event {
            color: var(--colorOutlines);
        }

        &-prev svg {
            transform: rotate(180deg);
        }
    }

    &-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
        text-align: center;
    }
}